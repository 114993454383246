<template>
  <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
    <template v-slot:error-message>
      <span class="pl-2 text-md">{{ errorMessage }}</span>
    </template>
  </error-toast>
  <main class="mx-36 my-10">
    <h1 class="text-4xl">Budget</h1>
    <LoadingSpinner v-if="!budgets" />
    <section class="budget-cards-wrapper my-8">
      <router-link :to="{name: 'budgetView', params: { year: budget.year }}" v-for="budget in budgets" :key="budget.year" class="budget-cards px-5 py-12 text-center rounded text-white">
        <div class="text-4xl">{{ budget.year }}</div>
      </router-link>
    </section>
  </main>
</template>

<script>
import Request from '@/services/requestHelper'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import moment from 'moment'

export default {
  name: 'Budget',
  async mounted() {
    try {
      this.setCardBackground()
      const { status, data } = await Request.getRequest('budget')

      if (status === 200) {
        this.budgets = data.data
      }
    } catch (error) {
      this.errorMessage = error.response.data.message
    }
  },
  updated() {
    this.$nextTick(() => {
      this.setCardBackground()
    })
  },
  components: {
    ErrorToast
  },
  data() {
    return {
      budgets: null,
      moment,
      errorMessage: null
    }
  },
  methods: {
    setCardBackground() {
      const elements = document.querySelectorAll('.budget-cards')

      elements.forEach((element, index) => {
        if (index === 0) {
          element.classList.add('bg-cardBackgroundColor-pink')
        } else if (index === 1) {
          element.classList.add('bg-cardBackgroundColor-purple')
        } else {
          element.classList.add('bg-cardBackgroundColor-green')
        }
      })
    }
  }
}
</script>

<style scoped>
.budget-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
}
</style>
